<template>
	<div>
		<beautiful-chat
			:always-scroll-to-bottom="alwaysScrollToBottom"
			:close="closeChat"
			:colors="colors"
			:is-open="isChatOpen"
			:message-list="messageList"
			:message-styling="messageStyling"
			:new-messages-count="newMessagesCount"
			:on-message-was-sent="onMessageWasSent"
			:open="openChat"
			:participants="participants"
			:showHeader="true"
			:show-close-button="true"
			:show-launcher="false"
			:show-emoji="false"
			:show-file="false"
			:show-typing-indicator="showTypingIndicator"
			:show-edition="false"
			:show-deletion="false"
			:disable-user-list-toggle="false"
			@scrollToTop="handleScrollToTop"
			@onType="handleOnType"
		>
			<template v-slot:header>
				<p>
					メッセージ : {{ participants.map((m) => m.name).join(" ") }}
				</p>
			</template>
			<template v-slot:user-avatar="{ user }">
				<div v-if="user && user.name" class="user-icon">
					<img :src="user.imageUrl" />
					<p>{{ user.name }}</p>
				</div>
			</template>
			<template v-slot:text-message-body="{ message }">
				<div class="message-body">{{ message.data.text }}</div>
				<template v-if="message.data.file.url">
					<button class="image-open" @click="clickImageOpener">
						▼
					</button>
					<div
						class="image"
						@click="clickImage(message.data.file.url)"
					>
						<img v-auth-image="`${message.data.file.url}`" />
					</div>
				</template>
				<div class="side">
					<div v-if="message.data.timestamp" class="timestamp">
						{{ message.data.timestamp }}
					</div>
					<div v-if="message.data.meta" class="meta">
						{{ message.data.meta }}
					</div>
				</div>
			</template>
			<template v-slot:system-message-body="{ message }">
				[System]: {{ message.text }}
			</template>
		</beautiful-chat>

		<button
			class="fs_chat_toggle_button"
			v-if="!isChatOpen"
			href="#"
			@click.prevent="openChat()"
		>
			メッセージを読む
		</button>
		<button
			class="fs_chat_toggle_button"
			v-else
			href="#"
			@click.prevent="closeChat()"
		>
			メッセージウィンドウを閉じる
		</button>
		<v-dialog v-model="imageDialog">
			<div class="clickarea" @click="closeDialog">
				<v-btn @click="closeDialog">閉じる</v-btn>
				<img v-auth-image="dialogImage" />
			</div>
		</v-dialog>
	</div>
</template>

<script>
import ScrollBooster from "scrollbooster";
import isMobile from "ismobilejs";
import axios from "axios";
import VueAuthImage from "vue-auth-image";
import Vue from "vue";

Vue.use(VueAuthImage);

export default {
	props: {
		cid: String,
		selected: String,
	},
	data() {
		return {
			reloadTimer: undefined,
			imageDialog: false,
			dialogImage: "",
			participants: [],
			messageList: [],
			newMessagesCount: 0,
			isChatOpen: false,
			showTypingIndicator: "",
			colors: {
				header: {
					bg: "#494949",
					text: "#fff",
				},
				launcher: {
					bg: "#63ff9a",
				},
				messageList: {
					bg: "#333",
				},
				sentMessage: {
					bg: "#63ff9a",
					text: "#000",
				},
				receivedMessage: {
					bg: "#3f3f3f",
					text: "#fff",
				},
				userInput: {
					bg: "#1a1a1a",
					text: "#fff",
				},
				userList: {
					bg: "#fff",
					text: "#212121",
				},
			},
			alwaysScrollToBottom: true,
			messageStyling: true,
			userIsTyping: false,
		};
	},
	mounted() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
		document
			.querySelector(".sc-user-input--text")
			.setAttribute("placeholder", "テキストを入力してください");
	},
	beforeDestroy() {
		this.closeChat();
	},
	methods: {
		handleTyping(text) {
			this.showTypingIndicator =
				text.length > 0
					? this.participants[this.participants.length - 1].id
					: "";
		},
		onMessageWasSent(message) {
			clearInterval(this.reloadTimer);
			this.showLoading();
			const params = {
				uid: this.$store.state.user.data.id,
				cid: this.cid,
				selected: this.selected,
				message: message.data.text,
				name: this.$store.state.user.data.name,
				userImage: this.$store.state.user.data.image,
			};
			axios
				.post(
					"https://" + this.config.defaultOriginString + "/api/chat",
					params
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						message.data.file = {
							url:
								"https://" +
								this.config.defaultOriginString +
								"/photo?cid=" +
								this.cid +
								"&name=" +
								this.selected +
								"&type=thumb",
						};
						this.messageList = [
							...this.messageList,
							Object.assign({}, message, { id: Math.random() }),
						];
						this.alwaysScrollToBottom = true;
						this.reloadTimer = setInterval(this.loadChat, 5000);
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		openChat() {
			this.showLoading();
			this.loadChat();
			this.reloadTimer = setInterval(this.loadChat, 5000);
			this.isChatOpen = true;
			this.newMessagesCount = 0;
			document.documentElement.classList.add("chat_window_active");
		},
		loadChat() {
			// Reload to retrieve the latest chat messages...
			let numMessages = 100;
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/chat?cid=" +
						this.cid +
						"&uid=" +
						this.$store.state.user.data.id +
						"&n=" +
						numMessages
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						console.log("Success: " + JSON.stringify(res.data));
						this.messageList = res.data.messages;
						this.participants = res.data.users;
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		closeChat() {
			clearInterval(this.reloadTimer);
			this.isChatOpen = false;
			document.documentElement.classList.remove("chat_window_active");
		},
		handleScrollToTop() {
			// called when the user scrolls message list to top
			// leverage pagination for loading another page of messages
			this.loadMoreMessages();
		},
		loadMoreMessages() {
			this.alwaysScrollToBottom = false;
		},
		messageStylingToggled(e) {
			this.messageStyling = e.target.checked;
		},
		handleOnType() {
			this.$root.$emit("onType");
			this.userIsTyping = true;
		},
		clickImageOpener(e) {
			let parentNode = e.target.parentNode;
			if (parentNode.classList.contains("open")) {
				parentNode.classList.remove("open");
			} else {
				parentNode.classList.add("open");
			}
		},
		clickImage(url) {
			this.imageDialog = true;
			this.dialogImage = url.substring(0, url.length - 5) + "raw";
		},
		closeDialog(e) {
			this.imageDialog = false;
			this.dialogImage = '';
		},
		showLoading: function () {
			this.$emit("showLoading", true);
		},
		hideLoading: function () {
			this.$emit("hideLoading", true);
		},
	},
};
</script>

