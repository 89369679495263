<template>
	<v-app class="fs_main">
		<!-- <ChatBlock2 v-if="showChatWindow" :type="chattype" @close="closeChatWindow" ></ChatBlock2> -->

		<v-container @click="closeChatWindow">
			<v-row>
				<v-col>
					<div class="fs_main_header">
						<h1>{{ projectName }}</h1>
						<!-- <v-btn class="edit"></v-btn> -->
						<span class="split">
							<h2>
								<span class="id">{{ placeId }}</span
								>{{ item.name }}
							</h2>
						</span>
					</div>
				</v-col>
			</v-row>

			<v-row>
				<v-col>
					<header class="fs_block_header">
						{{ selectedCameraName }}
						<span v-if="selectedCameraPhoneNumber && agentPostfix" class="sms2">
							<img src="/img/camera.png" @click.stop="setSmsDialog(selectedCameraPhoneNumber)" />
						</span>
					</header>
					<div class="fs_photo_slide" @scroll="onScrollPhotoSlide">
						<div class="fs_photo_slide_content">
							<figure
								v-for="(file, i) in files"
								:key="file.fileName"
								:index="file.fileName"
								:class="{
									selected: file.fileName == selectedPhotoId,
								}"
								@click="onClickImage(file.fileName)"
							>
								<div class="fs_cam_image">
									<span v-if="file.photoType != 'V' || !config.enableVideo">
										<img
											v-auth-image="
												`https://${config.defaultOriginString}/photo?cid=${cid}&name=${file.fileName}&type=thumb`
											"
										/>
										<div class="zoom">
											<img
												src="/img/zoom.png"
												@click="onClickZoom(file.fileName)"
											/>
										</div>
									</span>
									<span v-else>
										<video width="375" height="270" controls :src="videos[file.fileName]" />
									</span>
									<!-- <img src="/img/photo_test.jpg" /> -->
								</div>
								<figcaption>
									<span
										v-if="displayLatest(i) != ''"
										class="latest"
										>{{ displayLatest(i) }}</span
									>
									<span
										v-if="file.photoType == 'M'"
										class="motion"
										>動検知</span
									>
									<span
										v-if="file.photoType == 'V'"
										class="motion"
										>動画</span
									>
									<span
										v-if="file.photoType == 'S'"
										class="asap"
										>即時</span
									>
									<span class="date">{{
										convertDate(file.fileName)
									}}</span>
								</figcaption>
							</figure>
						</div>
					</div>

					<footer class="fs_block_footer">
						<img class="beacon" src="/img/arrow_right_photo.svg" />
						<v-btn
							v-for="item in cameras"
							:key="item.text"
							class="fs_button camera"
							:class="{
								selected: item.value == selectedCameraId,
							}"
							@click="changeCamera(item)"
							>{{ item.text }}</v-btn
						>

						<span class="fs_button past_images">{{ selectedCameraName }}の<a
							:href="`/images/${cid}`"
							>過去画像一覧</a> / <a
							:href="`/timelapse/${cid}`"
							>タイムラプス</a>へ
						</span>
					</footer>
				</v-col>
			</v-row>
			<!-- <v-row class="fs_chatbutton_row">
				<v-col>
					<button v-on:click="toggleChatWindow">メッセージを読む</button>
				</v-col>
			</v-row> -->
			<v-row class="fs_chatbutton_row">
				<v-col>
					<ChatBlock
						:cid="cid"
						:selected="selectedPhotoId"
						@showLoading="showLoading"
						@hideLoading="hideLoading"
					/>
				</v-col>
			</v-row>
			<v-row class="fs_map_row">
				<v-col>
					<header class="fs_block_header">現地マップ</header>
					<div class="fs_map">
						<MapBlock
							:zoom="zoom"
							:markers="markers"
							:fitness="fitness"
							:center="center"
							:maptype="maptype"
							:mapscrollwheel="mapscrollwheel"
							style="width: 100%; height: 100%"
						></MapBlock>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-footer>
			©2021 <a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
		<v-dialog v-model="imageDialog">
			<div class="clickarea" @click="hideImage">
				<v-btn @click="hideImage">閉じる</v-btn>
				<img v-auth-image="dialogImage" class="hd-image" />
			</div>
		</v-dialog>
		<v-dialog v-model="smsDialog">
			<v-card>
				<v-card-title class="headline grey lighten-2">
					SMSコマンド送信
				</v-card-title>
				<v-card-text>
					<div style="width: 90%; margin: 20px; text-align: left;">SMSコマンドをスマートフォンのSMSアプリを使って送信することによりカメラの操作が可能です。次の画面でそのままメッセージを送信してください。</div>
					<v-divider></v-divider>
					<div style="margin: 20px;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*500#')}`" style="text-decoration: none; color: #000;">
							<v-btn color="error" elevation="2" x-large>即時撮影送信</v-btn>
						</a>
					</div>
					<v-divider></v-divider>
					<div style="margin: 20px; text-align: left;">
						モード変更
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*200#0#')}`" style="text-decoration: none; color: #000;">
							<v-btn>静止画モード</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*200#1#')}`" style="text-decoration: none; color: #000;">
							<v-btn>動画モード</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*200#2#')}`" style="text-decoration: none; color: #000;">
							<v-btn>静止画+動画モード</v-btn>
						</a>
					</div>
					<div style="margin: 20px; text-align: left;">
						センサー感度変更
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*202#0#')}`" style="text-decoration: none; color: #000;">
							<v-btn>センサー感度 【高】 設定</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*202#1#')}`" style="text-decoration: none; color: #000;">
							<v-btn>センサー感度 【中】 設定</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*202#2#')}`" style="text-decoration: none; color: #000;">
							<v-btn>センサー感度 【低】 設定</v-btn>
						</a>
					</div>
					<div style="margin: 10px; margin-bottom: 20px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*202#3#')}`" style="text-decoration: none; color: #000;">
							<v-btn>センサー感度 【OFF】 設定</v-btn>
						</a>
					</div>
					<div style="margin: 20px; text-align: left;">
						タイムラプス設定変更
					</div>
					<div style="margin: 10px; margin-bottom: 20px; text-align:left;">
						<v-select v-model="timelapseItem" :items="timelapseItems" item-text="text" item-value="time" label="タイムラプス時間設定" dense outlined return-object></v-select>
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent(timelapseItem.time)}`" style="text-decoration: none; color: #000;">
							<v-btn>タイムラプス設定</v-btn>
						</a>
					</div>
					<div style="margin: 20px; text-align: left;">
						画像サイズ変更
					</div>
					<div style="margin: 10px; margin-bottom: 20px; text-align:left;">
						<v-select v-model="imageResoItem" :items="imageResoItems" item-text="text" item-value="size" label="画像サイズ" dense outlined return-object></v-select>
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent(imageResoItem.size)}`" style="text-decoration: none; color: #000;">
							<v-btn>画像サイズ設定</v-btn>
						</a>
					</div>
					<v-divider></v-divider>
					<div style="margin: 20px; text-align: left;">
						送信先メールアドレス追加 / 削除
					</div>
					<div style="margin: 10px; text-align:left; width: 80%;">
						メールアドレス: <v-text-field outlined v-model="email"></v-text-field>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*110#')}${email}${encodeURIComponent('#')}`" style="text-decoration: none; color: #000;">
							<v-btn>送信先メールアドレス 追加</v-btn>
						</a>
					</div>
					<div style="margin: 10px; margin-bottom: 20px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*111#')}${email}${encodeURIComponent('#')}`" style="text-decoration: none; color: #000;">
							<v-btn>送信先メールアドレス 削除</v-btn>
						</a>
					</div>
					<v-divider></v-divider>
					<div style="margin: 20px; text-align: left;">
						SMS送信元電話番号追加 / 削除
					</div>
					<div style="margin: 10px; text-align:left; width: 80%;">
						電話番号: <v-text-field outlined v-model="phone"></v-text-field>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*100#')}${phone}${encodeURIComponent('#')}`" style="text-decoration: none; color: #000;">
							<v-btn>SMS送信元電話番号 追加</v-btn>
						</a>
					</div>
					<div style="margin: 10px; text-align:left;">
						<a :href="`sms:${phoneNumber}${agentPostfix}body=${encodeURIComponent('*101#')}${phone}${encodeURIComponent('#')}`" style="text-decoration: none; color: #000;">
							<v-btn>SMS送信元電話番号 削除</v-btn>
						</a>
					</div>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="smsDialog = false">
						閉じる
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script>
import MapBlock from "@/components/MapBlock.vue";
import ChatBlock from "@/components/ChatBlock.vue";
import axios from "axios";
import Vue from "vue";
import VueAuthImage from "vue-auth-image";
import ScrollBooster from "scrollbooster";
import isMobile from "ismobilejs";

Vue.use(VueAuthImage);

export default {
	computed: {
		item: function () {
			return this.$store.getters.getPlaceById(this.$route.params.id);
		},
		center: function () {
			return {
				lat: parseFloat(this.item.latitude),
				lng: parseFloat(this.item.longitude),
			};
		},
		markers: function () {
			let data = [];
			let element = {
				id: this.item.id,
				lat: parseFloat(this.item.latitude),
				lng: parseFloat(this.item.longitude),
				icon: {
					color: "#dd445f",
				},
			};
			data.push(element);
			return data;
		},
	},
	components: {
		MapBlock,
		ChatBlock,
	},
	created() {
		axios.defaults.headers.common["Authorization"] =
			"Bearer " + this.$store.state.auth.data.token;
	},
	mounted() {
		if (this.$route.query.cid) {
			this.cid = this.$route.query.cid
			this.selectedCameraId = this.$route.query.cid;
			this.selectedCameraName = this.getCamera(this.cid, this.$store.getters.getPlaceById(this.$route.params.id).cameras).name;
			this.selectedCameraPhoneNumber = this.getCamera(this.cid, this.$store.getters.getPlaceById(this.$route.params.id).cameras).phoneNumber;
		}
		this.is_mobile = isMobile(window.navigator);
		this.requestCamera(this.cid);
		this.scrollTop();
		if (
			navigator.userAgent.indexOf("iPhone") > 0 ||
			navigator.userAgent.indexOf("iPad") > 0 ||
			navigator.userAgent.indexOf("iPod") > 0
		) {
			this.agentPostfix = "&";
		} else if (navigator.userAgent.indexOf("Android") > 0) {
			this.agentPostfix = "?";
		}
	},
	methods: {
		getCamera: function(cid, cameras) {
			for (var i = 0; i < cameras.length; i++) {
				if (cid == cameras[i].id) {
					return cameras[i]
				}
			}
			return null;
		},
		setVideoUrl: function(cid, fileName) {
			axios.get("https://" + this.config.defaultOriginString + "/photo?cid=" + cid + "&name=" + fileName + "&type=video",
				{headers: {
					Authorization: "Bearer " + this.$store.state.auth.data.token,
				}})
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.status == "200") {
						// console.log("Video Success: " + JSON.stringify(res.data));
						this.$set(this.videos, fileName, res.data);
					} else {
						console.log("Video Fail: " + JSON.stringify(res));
					}
				}).catch((error) => {
					console.log("Video Error: " + error);
				}
			);
		},
		setBigimageUrl: function(cid, fileName) {
			axios.get("https://" + this.config.defaultOriginString + "/photo?cid=" + cid + "&name=" + fileName + "&type=bigimage",
				{headers: {
					Authorization: "Bearer " + this.$store.state.auth.data.token,
				}})
				.then((res) => {
					// 成功した場合
					if (res && res.data && res.status == "200") {
						console.log("Big Image Success: " + JSON.stringify(res.data));
						this.$set(this.bigimages, fileName, res.data);
					} else {
						console.log("Big Image Fail: " + JSON.stringify(res));
					}
				}).catch((error) => {
					console.log("Big Image Error: " + error);
				}
			);
		},
		requestCamera: function (id) {
			this.showLoading();
			let numPhotos = 7;
			this.setScrollBooster(numPhotos);
			axios
				.get(
					"https://" +
						this.config.defaultOriginString +
						"/api/photo-list?cid=" +
						id +
						"&n=" +
						numPhotos
				)
				.then((res) => {
					this.hideLoading();
					// 成功した場合
					if (res && res.data && res.data.status == "success") {
						for (var i = 0; i < res.data.list.length; i++) {
							if (res.data.list[i].photoType == "V") {
								this.setVideoUrl(id, res.data.list[i].fileName);
							} else {
								if (this.config.bigImages) {
									this.setBigimageUrl(id, res.data.list[i].fileName);
								}
							}
							Vue.set(this.files, i, res.data.list[i]);
						}
						this.loadCount++;
						this.cid = id;
						this.selectedPhotoId = this.files[0].fileName;
						console.log("Success: " + JSON.stringify(res.data));
					} else {
						console.log("Fail: " + JSON.stringify(res));
					}
				})
				.catch((error) => {
					this.hideLoading();
					console.log(error);
				});
		},
		changeCamera: function (item) {
			this.selectedCameraId = item.value;
			this.selectedCameraName = item.text;
			this.selectedCameraPhoneNumber = item.phoneNumber;
			this.requestCamera(item.value);
		},
		convertDate: function (n) {
			return (
				n.substring(0, 4) +
				"-" +
				n.substring(4, 6) +
				"-" +
				n.substring(6, 8) +
				" " +
				n.substring(8, 10) +
				":" +
				n.substring(10, 12) //+
				//":" +
				//n.substring(12, 14)
			);
		},
		displayLatest: function (key) {
			if (key == 0) return "最新";
			else return "";
		},
		scrollTop: function () {
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		toggleChatWindow: function (force_close) {
			if (this.showChatWindow) {
				document.documentElement.classList.remove(
					"chat_window_animation",
					"chat_window_active",
					"chat_window_open"
				);
				setTimeout((e) => {
					this.showChatWindow = false;
				}, 500);
			} else {
				this.showChatWindow = true;
			}
		},
		closeChatWindow: function () {
			if (
				document.documentElement.classList.contains("chat_window_open")
			) {
				this.toggleChatWindow();
			}
		},
		onClickImage: function (i) {
			this.selectedPhotoId = i;
		},
		onClickZoom: function (i) {
			this.showImage(i);
		},
		showImage: function (key) {
			this.imageDialog = true;
			if (this.config.bigImages) {
				this.dialogImage = this.bigimages[key];
			} else {
				// this.dialogImage = '/img/photo_test_hd.jpg'
				this.dialogImage =
					"https://" +
					this.config.defaultOriginString +
					"/photo?cid=" +
					this.cid +
					"&name=" +
					key +
					"&type=raw";
			}
		},
		hideImage: function () {
			this.imageDialog = false;
			this.dialogImage = "";
		},
		setScrollBooster: function (n) {
			// デスクトップでは写真スライド をマウスドラッグでスクロール可能にする
			if (!this.is_mobile.any) {
				document
					.querySelector(".fs_photo_slide_content")
					.setAttribute("style", "width:" + 391 * n + "px");
				new ScrollBooster({
					viewport: document.querySelector(".fs_photo_slide"),
					content: document.querySelector(".fs_photo_slide_content"),
					scrollMode: "native",
					direction: "horizontal",
				});
			}
		},
		onScrollPhotoSlide: function () {
			if (!this.photoSlideIsScrolled) {
				this.photoSlideIsScrolled = true;
				document.querySelector(".beacon").classList.add("hide");
			}
		},
		showLoading: function () {
			this.$emit("loadShowHide", true);
		},
		hideLoading: function () {
			this.$emit("loadShowHide", false);
		},
		setSmsDialog: function(num) {
			this.phoneNumber = num;
			this.smsDialog = true;
		}
	},
	data() {
		return {
			timelapseItem: {text: "24時間", time: "*T#DF#"},
			timelapseItems: [
				{text: "24時間", time: "*T#DF#"},
				{text: "12時間", time: "*T#DE#"},
				{text: "8時間", time: "*T#DD#"},
				{text: "6時間", time: "*T#DC#"},
				{text: "4時間", time: "*T#DB#"},
				{text: "3時間", time: "*T#DA#"},
				{text: "2時間", time: "*T#D9#"},
				{text: "1時間", time: "*T#D8#"},
				{text: "30分", time: "*T#D7#"},
				{text: "10分", time: "*T#D6#"},
				{text: "5分", time: "*T#D5#"},
				{text: "3分", time: "*T#D4#"},
				{text: "1分", time: "*T#D3#"},
				{text: "30秒", time: "*T#D2#"},
				{text: "オフ", time: "*T#D1#"}
			],
			imageResoItem: {text: "300万画素", size: "*C#E1#"},
			imageResoItems: [
				{text: "300万画素", size: "*C#E1#"},
				{text: "500万画素", size: "*C#E2#"},
				{text: "800万画素", size: "*C#E3#"},
				{text: "1000万画素", size: "*C#E4#"},
				{text: "1200万画素", size: "*C#E5#"}
			],
			fontsize: 0,
			// Google Map
			zoom: 16,
			fitness: false,
			maptype: "roadmap",
			mapscrollwheel: false,
			chattype: "chat",
			files: [],
			videos: {},
			bigimages: {},
			loadCount: 0,
			projectName: this.$store.state.project.data.name,
			placeId: (
				"000" +
				this.$store.getters.getPlaceById(this.$route.params.id).id
			).slice(-3),
			cid: this.$store.getters.getPlaceById(this.$route.params.id)
				.cameras[0].id,
			cameras: this.$store.getters
				.getPlaceById(this.$route.params.id)
				.cameras.map(function (item) {
					return { text: item.name, value: item.id, phoneNumber: item.phoneNumber };
				}),
			selectedCameraId: this.$store.getters.getPlaceById(
				this.$route.params.id
			).cameras[0].id,
			selectedCameraName: this.$store.getters.getPlaceById(
				this.$route.params.id
			).cameras[0].name,
			selectedCameraPhoneNumber: this.$store.getters.getPlaceById(this.$route.params.id).cameras[0].phoneNumber,
			selectedPhotoId: undefined,
			showChatWindow: false,
			is_mobile: null,
			photoSlideIsScrolled: false,
			imageDialog: false,
			dialogImage: "",
			agentPostfix: "",
			smsDialog: false,
			phoneNumber: "",
			email: "",
			phone: ""
		};
	},
};
</script>

